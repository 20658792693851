import {  useMemo } from "react";
import { Box, darken } from "@mui/material";
import { CabButton } from "@CabComponents/CabButton";
import { CabControlLabel } from "@CabComponents/CabControlLabel";
import { CabDurationPicker } from "@CabComponents/CabDurationPicker";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Controller, useForm } from "react-hook-form";
import { Leader, Meeting, MeetingStatus } from "../../../store";
import { 
  CabTextTokenInput, transformMarkupToSimpleTemplate, transformMarkupToText
} from "@CabComponents/CabTextTokenInput";
import { getTemplateVars } from "../../../utils/scheduleUtils";
import { getLeaderIconSrc, getLeadersForScheduling } from "../../../utils/leaderUtils";
import colors from "../../../colors";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoHelpCircleOutline } from 'react-icons/io5';
import { CabExecPicker } from "@CabComponents/CabExecPicker";
import CabAvatar from "@CabComponents/CabAvatar";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { MEETING_STATUS_LABELS, REUSABLE_MEETING_STATUS_LABELS } from "../../../constants";
import { CabToggleChip } from "@CabComponents/CabToggleChip";


const statusColors: Record<string, string> = {
  '1': colors.neutral,
  '2': colors.greenPrimary,
  '3': darken(colors.coralPrimary, 0.05),
  '4': darken(colors.coralPrimary, 0.05),
  '5': darken(colors.coralPrimary, 0.05),
};

export type NewMeetingSubmitProps = Partial<Meeting>;

export type NewMeetingModalProps = {
  open: boolean
  onClose: () => void
  submitNew: (data: NewMeetingSubmitProps) => Promise<void>
  leaders: Leader[];
  isPoll?: boolean;
  isReusable?: boolean;
};

const NewMeetingModal = (
  { open, onClose, submitNew, isPoll, isReusable, leaders }: NewMeetingModalProps
): React.ReactElement => {
  const showTokenInputs = !isPoll;

  const templateVars = useMemo(() => {
    return getTemplateVars();
  }, []);

  const leadersForScheduling = getLeadersForScheduling(leaders);

  const { control, handleSubmit, reset, formState } = useForm({ defaultValues: {
    title: '',
    duration_minutes: 30,
    num_expected_participants: 1,
    internal_label: '',
    notes: '',
    leaders: [],
    status: MeetingStatus.PENDING,
  } });

  const handleSubmitNewMeeting = async (data: NewMeetingSubmitProps) => {
    const meetingData = { ...data };
    if (meetingData.title) meetingData.title = transformMarkupToSimpleTemplate(meetingData.title);
    if (!meetingData.internal_label) meetingData.internal_label = transformMarkupToText(meetingData.title);
    if (!isReusable) meetingData.internal_label = undefined;

    await submitNew(meetingData);
    reset();
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  return <CabModal
    open={open}
    onClose={onClose}
    title="Add a Meeting Record"
    actionButtons={
      <>
        <CabButton
          buttonType="secondary"
          onClick={handleCancel}
          disabled={formState.isSubmitting}
        >
          Cancel
        </CabButton>
        <CabButton
          onClick={handleSubmit(handleSubmitNewMeeting)}
          disabled={formState.isSubmitting || !formState.isValid}
        >
          Save Meeting
        </CabButton>
      </>
    }
  >
    <form>
      {isReusable &&
          <CabControlLabel
            label="Internal Meeting Label"
            labelPlacement="top"
            sx={{
              width: "100%",
              alignItems: "inherit",
              marginBottom: 1
            }}
            control={<Controller
              name="internal_label"
              control={control}
              render={({
                field: { ref, ...field },
              }) => (
                <CabTextInput
                  {...field}
                  inputRef={ref}
                  multiline
                  minRows={1}
                  maxRows={3}
                  formControlSx={{ flex: 1 }}
                  endIcon={
                    <CabTooltip
                      wrapWithSpan
                      title={`This label helps you organize your reusable meetings. It will not be on
                        any events, and your attendees will never see it.`}
                      sx={{alignSelf: 'center'}}
                    >
                      <CabIcon Icon={IoHelpCircleOutline} sx={{ fontSize: '20px' }} />
                    </CabTooltip>
                  }
                />
              )}
            />}
          />
      }
      <CabControlLabel
        label="Title"
        labelPlacement="top"
        sx={{
          width: "100%",
          alignItems: "inherit",
          marginBottom: 1
        }}
        control={<Controller
          name="title"
          control={control}
          rules={{ required: true }}
          render={({
            field: { ref, ...field },
          }) => (
            showTokenInputs ? (
              <CabTextTokenInput
                {...field}
                inputRef={ref}
                required
                multiline
                templateVars={templateVars}
                inputProps={{ style: { maxHeight: 75 } }}
                tokenColor={colors.black400}
              />
            ) : (
              <CabTextInput inputRef={ref} {...field} />
            )
          )}
        />}
      />

      <CabControlLabel
        label="Teammates"
        labelPlacement="top"
        sx={{
          width: "100%",
          alignItems: "inherit",
          marginBottom: 1
        }}
        control={
          <Controller
            name="leaders"
            control={control}
            rules={{ required: true, minLength: 1 }}
            render={({ field: { ref, ...field } }) => (
              <Box
                sx={{
                  border: '1px solid', borderColor: colors.black200, borderRadius: 1, paddingRight: 1, paddingLeft: 1,
                }}
              >
                <CabExecPicker<number[]>
                  multiple
                  options={leadersForScheduling.map(leader => {
                    return {
                      value: leader.id,
                      label: `${leader.first_name} ${leader.last_name}`,
                      icon: <CabAvatar
                        src={getLeaderIconSrc(leader)}
                        color={leader.color}
                        name={`${leader.first_name} ${leader.last_name}`}
                        size="small"
                      />,
                    };
                  })}
                  fullWidth
                  placeholder="Select Teammates"
                  {...field}
                />
              </Box>
            )}
          />
        }
      />

      <CabControlLabel
        label="Internal Notes"
        labelPlacement="top"
        sx={{
          width: '100%',
          alignItems: "inherit",
          marginBottom: 1
        }}
        control={<Controller
          name="notes"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <CabTextInput
              fullWidth
              maxRows={4}
              minRows={3}
              multiline
              {...field}
            />
          )}
        />}
      />
      <Box display={"flex"} justifyContent={"space-between"} gap={2}>
        <CabControlLabel
          label="Duration"
          labelPlacement="top"
          sx={{
            width: "30%",
            alignItems: "inherit",
            marginBottom: 1,
            "& .MuiFormControlLabel-label": {
              marginBottom: 1,
            }
          }}
          control={<Controller
            name="duration_minutes"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, ref, ...props },
            }) => (
              <CabDurationPicker durationMinutes={value || 0} {...props} />
            )}
          />}
        />

        <CabControlLabel
          label="Status"
          labelPlacement="top"
          sx={{
            width: "50%",
            alignItems: "inherit",
            marginBottom: 1,
            "& .MuiFormControlLabel-label": {
              marginBottom: 1,
            }
          }}
          control={
            <Controller
              name="status"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <CabDropdown
                  options={
                    Object.keys(isReusable ? REUSABLE_MEETING_STATUS_LABELS : MEETING_STATUS_LABELS)
                      .map((statusId) => {
                        const id = Number(statusId);
                        return {
                          value: id,
                          label: isReusable ? REUSABLE_MEETING_STATUS_LABELS[id] : MEETING_STATUS_LABELS[id],
                        };
                      })
                  }
                  overrides={{
                    renderValue: (value: unknown) => {
                      const id = Number(value);
                      const color = statusColors[id] || colors.neutral;
                      const label = isReusable ? REUSABLE_MEETING_STATUS_LABELS[id] : MEETING_STATUS_LABELS[id];
                      return (
                        <CabToggleChip
                          chipColor={color}
                          sx={{ 
                            color: color === colors.neutral ? undefined : colors.white900, 
                            width: '100%', 
                            height: 20 
                          }}
                          label={label}
                          selected={true}
                        />
                      );
                    },
                  }}
                  sx={{ height: 32, width: '100%' }}
                  {...field}
                />
              )}
            />
          }
        />

        {isPoll &&
            <>
              <CabControlLabel
                label="# Respondents"
                labelPlacement="top"
                sx={{
                  width: "25%",
                  alignItems: "inherit",
                  marginBottom: 1,
                  "& .MuiFormControlLabel-label": {
                    marginBottom: 1,
                  }
                }}
                control={<Controller
                  name="num_expected_participants"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <CabTextInput
                      {...field}
                      inputRef={ref}
                      required
                      size={"xsmall"}
                      inputProps={{
                        min: 1,
                        type: 'number',
                      }}
                    />
                  )} />}
              />
            </>
        }
      </Box>
    </form>
  </CabModal>;
};

export default NewMeetingModal;